import { BaseModel } from '../BaseModel'
/**
 * Class Endpoint
 *
 * @extends BaseModel
 */
export class EndpointDatabaseSetting extends BaseModel {
  constructor(props) {
    super(props)

    this.server = props.server || ''
    this.database = props.database || ''
    this.username = props.username || ''
    this.password = props.password || ''
    /**
     * Save the initial state of the object for comparing changes
     */
    this._initialValues = { ...this, _initialValues: null }
  }
}
