import { BaseModel } from '../BaseModel'
import adminApiClient from '../../api/admin-api-client'
import { EndpointDatabaseSetting } from './EndpointDatabaseSetting'
/**
 * Class Endpoint
 *
 * @extends BaseModel
 */
export class Endpoint extends BaseModel {
  constructor(props) {
    super(props)

    this.endpointId = props.endpointId || null
    this.companyId = props.companyId || 0
    this.endpointName = props.endpointName || ''
    this.endpointUrl = props.endpointUrl || ''
    this.endpointUrlV2 = props.endpointUrlV2 || ''
    this.endpointType = props.endpointType || 'Http'
    this.crmpath = props.crmpath || ''
    this.internalEp = props.internalEp || false
    this.systemId = props.systemId || null
    this.properties = props.properties || []
    this.system = props.system || null
    this.lastCheck = props.lastCheck || null
    this.lastSeen = props.lastSeen || null
    this.databaseSettings = props.databaseSettings
      ? new EndpointDatabaseSetting(props.databaseSettings)
      : new EndpointDatabaseSetting([])
    /**
     * Save the initial state of the object for comparing changes
     */
    this._initialValues = { ...this, _initialValues: null }
  }

  transformForComparison(model) {
    const onlyValues = (props) => {
      return props
        .map((prop) => {
          return {
            propertyValue: prop.propertyValue ? prop.propertyValue.toString().toLowerCase() : prop.propertyValue,
          }
        })
        .filter((prop) => !!prop.propertyValue)
    }
    model.properties = onlyValues(model.properties)
    return model
  }

  /**
   * @override
   */
  update() {
    const endpointToBeSaved = { ...this }
    endpointToBeSaved._initialValues = undefined
    endpointToBeSaved.lastCheck = undefined
    endpointToBeSaved.lastSeen = undefined
    this.isLoading = true
    return adminApiClient
      .updateEndpoint(endpointToBeSaved.endpointId, { data: endpointToBeSaved })
      .then((response) => {
        if (response.status === 200) {
          this.isLoading = false
          return true
        }
        this.resetInitialValues()
        throw new Error('Failed to save!')
      })
      .catch((ex) => {
        this.isLoading = false
        throw ex
      })
  }
}
